<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-tabs color="black" v-model="tabs" show-arrows>
        <v-tab class="fonte-bold">Tudo</v-tab>
        <v-tab class="fonte-bold">Rede</v-tab>
        <v-tab class="fonte-bold">Rentabilidade</v-tab>
        <v-tab class="fonte-bold">PL</v-tab>
        <v-tab class="fonte-bold">Saque</v-tab>
        <v-tab class="fonte-bold">Compras</v-tab>
      </v-tabs>
      <v-flex xs12>
        <div class="expande-horizontal">
          <v-list style="min-width: 100%;" class="pa-0 ma-0" three-line>
            <template v-for="(item, index) in transactions">
              <v-list-item
                :key="item._id"
                v-show="item.current_status !== 'queued'"
              >
                <v-list-item-content>
                  <v-list-item-title class="fonte-bold">
                    {{ item.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    {{ $helper.formataSaldo(item.value) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="font-weight-light"
                    style="font-size: 8pt;"
                  >
                    {{
                      $moment(item.created_at)
                        .locale("pt-br")
                        .format("llll")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-show="item.current_status !== 'queued'"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </div>
      </v-flex>

      <Empty v-if="transactions.length === 0"></Empty>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getTransactions"]),
    transactions() {
      if (this.tabs == 0) return this.getTransactions;
      if (this.tabs == 1)
        return this.getTransactions.filter(
          item => item.transaction_type == "unilevel"
        );
      if (this.tabs == 2)
        return this.getTransactions.filter(
          item => item.transaction_type == "investment"
        );
      if (this.tabs == 3)
        return this.getTransactions.filter(
          item => item.transaction_type == "pl"
        );
      if (this.tabs == 4)
        return this.getTransactions.filter(item => item.withdraw);
      if (this.tabs == 5)
        return this.getTransactions.filter(
          item => item.transaction_type == "USD"
        );
      return [];
    }
  },
  data() {
    return {
      tabs: 0
    };
  },
  methods: {
    ...mapActions(["listTransactions"])
  },
  created() {
    this.listTransactions();
  }
};
</script>
